<template>
  <order-detail @closeModal="closeOrderDetail($event)" />
</template>

<script>
import { mapActions } from 'vuex'
import OrderDetail from '../component/OrderDetail.vue'

export default {
  components: {
    OrderDetail
  },
  data () {
    return {
      orderSelectedId: 'create'
    }
  },
  mounted () {
    this.selectOrderId({
      id: 'create',
      isMainOrder: true
    })
  },
  methods: {
    ...mapActions({
      selectOrderId: 'Order/selectOrderId'
    }),
    closeOrderDetail () {
      this.$router.push({ name: 'OrderList' })
    }
  }
}
</script>
